<template>
  <div class="layout-padding">
    <PageTitle
      class="my-8"
      :title="`<div class='flex'><div class='lora-regular me-3'>${content.hello} </div>  ${user.name}</div>`"
      :childOne="content.account"
      type="withBg"
    />
    <div class="grid grid-cols-7 gap-x-0 sm:gap-x-14 pb-16">
      <div class="col-span-2 xl:block hidden">
        <div class="account-tabs lora-bold">
          <router-link to="/account/profile">
            <div
              class="tab flex justify-between items-center"
              :class="{ 'text-secondaryColor': lastRoute == 'profile' }"
            >
              {{ content.profile }}
              <i
                class="
                  fas
                  text-size-12
                  fa-chevron-right
                  btn-arrow-icon
                  text-base
                "
              ></i>
            </div>
          </router-link>
          <router-link to="/account/passwordsetting">
            <div
              class="tab flex justify-between items-center"
              :class="{ 'text-secondaryColor': lastRoute == 'passwordsetting' }"
            >
              {{ content.passwordSetting }}
              <i
                class="
                  fas
                  text-size-12
                  fa-chevron-right
                  btn-arrow-icon
                  text-base
                "
              ></i>
            </div>
          </router-link>
          <router-link to="/account/orderhistory">
            <div
              class="tab flex justify-between items-center"
              :class="{ 'text-secondaryColor': lastRoute == 'orderhistory' }"
            >
              {{ content.orderHistory }}
              <i
                class="
                  fas
                  text-size-12
                  fa-chevron-right
                  btn-arrow-icon
                  text-base
                "
              ></i>
            </div>
          </router-link>
          <router-link to="/account/myreviews">
            <div
              class="tab flex justify-between items-center"
              :class="{ 'text-secondaryColor': lastRoute == 'myreviews' }"
            >
              {{ content.reviews }}
              <i
                class="
                  fas
                  text-size-12
                  fa-chevron-right
                  btn-arrow-icon
                  text-base
                "
              ></i>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-span-7 xl:col-span-5">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  mounted() {
    this.$store.dispatch("reviews/reviews");
    this.$store.dispatch("orders/orders");
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    lastRoute() {
      return this.$route.path.split("/").pop();
    },
  },
};
</script>